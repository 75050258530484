import React from "react";

interface NewsItemProps {
  item: {
    $id: string;
    article: string;
    related: string;
    time: string;
    $createAt: string;
  };
  darkMode: boolean;
}

const NewsItem: React.FC<NewsItemProps> = ({ item, darkMode }) => {
  return (
    <div
      className={`p-4 rounded-lg shadow transition-all duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1 ${
        darkMode
          ? "bg-neutral-800 dark:text-white shadow-neutral-900"
          : "bg-white"
      }`}
    >
      <p className="text-sm text-gray-400">
        ({item.related}) - {item.time}
      </p>
      <p>{item.article}</p>
    </div>
  );
};

export default NewsItem;
