import { Databases, Query } from "appwrite";

const fetchRecentDocuments = async (
  databases: Databases,
  databaseId: string,
  collectionId: string,
  hoursAgo: number = 5
) => {
  let allDocuments: any[] = [];
  let offset = 0;
  const limit = 25; // Appwrite's default limit

  const threeHoursAgo = new Date();
  threeHoursAgo.setHours(threeHoursAgo.getHours() - hoursAgo);
  const timestamp = threeHoursAgo.toISOString();

  while (true) {
    const response = await databases.listDocuments(databaseId, collectionId, [
      Query.greaterThan("$createdAt", timestamp),
      Query.limit(limit),
      Query.offset(offset),
    ]);

    allDocuments = allDocuments.concat(response.documents);

    // Break if we received fewer documents than the limit
    if (response.documents.length < limit) {
      break;
    }
    offset += limit;
  }

  return allDocuments;
};

export default fetchRecentDocuments;
