import React, { useState } from "react";
import { Account, Databases } from "appwrite";

interface LoginFormProps {
  account: Account;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setNews: (news: any[]) => void;
  darkMode: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  account,
  setIsLoggedIn,
  setNews,
  darkMode,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState<string | null>(null);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await account.createEmailPasswordSession(email, password);
      setIsLoggedIn(true);

      const database = new Databases(account.client);
      const response = await database.listDocuments("prod", "news");
      const newsItems = response.documents
        .map((doc: any) => ({
          $id: doc.$id,
          article: doc.article,
          related: doc.related,
          time: doc.time,
          $createAt: doc.$createdAt,
        }))
        .sort(
          (a, b) =>
            new Date(b.$createAt).getTime() - new Date(a.$createAt).getTime()
        );
      setNews(newsItems);
      setLoginError(null); // Clear any previous error
    } catch (error) {
      console.error("Login failed:", error);
      setLoginError("Login failed. Please check your email and password.");
    }
  };

  return (
    <form onSubmit={handleLogin} className="space-y-4">
      {loginError && <div className="text-red-500 text-sm">{loginError}</div>}
      <div>
        <label
          className={`block text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          Email
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
            darkMode
              ? "bg-neutral-800 text-white border-neutral-700"
              : "bg-white border-gray-300"
          }`}
          required
        />
      </div>
      <div>
        <label
          className={`block text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          Password
        </label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
            darkMode
              ? "bg-neutral-800 text-white border-neutral-700"
              : "bg-white border-gray-300"
          }`}
          required
        />
      </div>
      <button
        type="submit"
        className="w-full px-4 py-2 bg-neutral-700 text-white rounded"
      >
        Login
      </button>
    </form>
  );
};

export default LoginForm;
