import React, { useRef, useState, useEffect } from "react";
import NewsItem from "./NewsItem";

interface NewsListProps {
  sortedGroupedNews: { [key: string]: { [key: string]: any[] } };
  collapsed: { [key: string]: boolean };
  timeCollapsed: { [key: string]: boolean };
  toggleCollapse: (date: string) => void;
  toggleTimeCollapse: (time: string) => void;
  darkMode: boolean;
}

const NewsList: React.FC<NewsListProps> = ({
  sortedGroupedNews,
  collapsed,
  timeCollapsed,
  toggleCollapse,
  toggleTimeCollapse,
  darkMode,
}) => {
  const [visibleDates, setVisibleDates] = useState<{ [key: string]: boolean }>(
    {}
  );

  const dateRefs = useRef<{ [key: string]: HTMLHeadingElement | null }>({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const newVisibleDates = { ...visibleDates };
        entries.forEach((entry) => {
          newVisibleDates[entry.target.id] = entry.isIntersecting;
        });
        setVisibleDates(newVisibleDates);
      },
      { threshold: 0.1 }
    );

    const currentRefs = dateRefs.current;
    Object.keys(currentRefs).forEach((date) => {
      if (currentRefs[date]) {
        observer.observe(currentRefs[date]!);
      }
    });

    return () => {
      Object.keys(currentRefs).forEach((date) => {
        if (currentRefs[date]) {
          observer.unobserve(currentRefs[date]!);
        }
      });
    };
  }, [visibleDates]);

  return (
    <div>
      {Object.keys(sortedGroupedNews).map((date) => (
        <div key={date}>
          <div
            className={`p-4 rounded-lg shadow ${
              darkMode
                ? "bg-neutral-800 dark:text-white shadow-neutral-900"
                : "bg-white"
            }`}
          >
            <h2
              id={date}
              ref={(el) => (dateRefs.current[date] = el)}
              className="text-lg font-semibold cursor-pointer"
              onClick={() => toggleCollapse(date)}
            >
              {date}
            </h2>
            {!collapsed[date] && (
              <div className="space-y-2">
                {Object.keys(sortedGroupedNews[date]).map((time) => (
                  <div key={time}>
                    <div
                      className={`sticky top-14 p-4 mt-2 mb-4 rounded-lg shadow cursor-pointer flex justify-between items-center ${
                        darkMode
                          ? "bg-cyan-950 shadow-neutral-900"
                          : "bg-cyan-200"
                      }`}
                      style={{ zIndex: 10 }}
                      onClick={() => toggleTimeCollapse(time)}
                    >
                      <p
                        className={`text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-600"
                        }`}
                      >
                        {time}
                      </p>
                      {!visibleDates[date] && (
                        <p
                          className={`text-sm ${
                            darkMode ? "text-gray-400" : "text-gray-600"
                          }`}
                        >
                          {date}
                        </p>
                      )}
                    </div>
                    {!timeCollapsed[time] && (
                      <div className="space-y-2">
                        {sortedGroupedNews[date][time].map((item) => (
                          <NewsItem
                            key={item.$id}
                            item={item}
                            darkMode={darkMode}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsList;
