import React from "react";
import { Moon, Sun } from "lucide-react";
import botImg from "../assets/logo.png";

interface HeaderProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
  isLoggedIn: boolean;
  handleLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({
  darkMode,
  toggleDarkMode,
  isLoggedIn,
  handleLogout,
}) => {
  return (
    <div
      className={`sticky top-0 z-50 flex justify-between mb-4 items-center py-2 px-4 ${
        darkMode ? "bg-neutral-900" : "bg-white"
      }`}
    >
      <div className="flex items-center">
        <button
          onClick={toggleDarkMode}
          className="flex items-center justify-center w-12 h-6 rounded-full bg-gray-300 dark:bg-neutral-600 focus:outline-none mr-2"
        >
          <div
            className={`w-5 h-5 rounded-full transition-transform duration-300 ${
              darkMode
                ? "translate-x-2 bg-neutral-800"
                : "-translate-x-2 bg-white"
            }`}
          />
        </button>
        {darkMode ? (
          <Moon className="h-5 w-5 mr-4 text-neutral-300" />
        ) : (
          <Sun className="h-5 w-5 mr-4" />
        )}
      </div>
      <div className="flex items-center">
        <h1
          className={`text-xl font-bold ml-2 ${
            darkMode ? "text-white" : "text-black"
          }`}
        >
          Gist
        </h1>
        <img src={botImg} alt="Logo" className="w-8 h-8 mx-auto" />
      </div>
      {isLoggedIn && (
        <button
          onClick={handleLogout}
          className={`px-4 py-2 ${
            darkMode ? "bg-neutral-800 dark:text-white" : "p-4 shadow bg-white"
          } text-gray-700 rounded`}
        >
          Logout
        </button>
      )}
    </div>
  );
};

export default Header;
