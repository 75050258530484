import React, { useEffect, useState } from "react";
import { Client, Databases, Account } from "appwrite";
import { format } from "date-fns";
import fetchRecentDocuments from "./utils/fetchAllDocuments";
import LoginForm from "./components/LoginForm";
import NewsList from "./components/NewsList";
import Header from "./components/Header";
import botImg from "./assets/logo.png";

const client = new Client();
client
  .setEndpoint("https://cloud.appwrite.io/v1")
  .setProject("ai-news-summarizer");

const databases = new Databases(client);
const account = new Account(client);

interface NewsItem {
  $id: string;
  article: string;
  related: string;
  time: string;
  $createAt: string;
}

const App: React.FC = () => {
  const [news, setNews] = useState<NewsItem[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem("darkMode") === "true"
  );
  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>({});
  const [timeCollapsed, setTimeCollapsed] = useState<{
    [key: string]: boolean;
  }>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSessionAndFetchNews = async () => {
      try {
        await account.get();
        setIsLoggedIn(true);
        const newsItems = await fetchRecentDocuments(databases, "prod", "news");
        const sortedNewsItems = newsItems
          .map((doc: any) => ({
            $id: doc.$id,
            article: doc.article,
            related: doc.related,
            time: doc.time,
            $createAt: doc.$createdAt,
          }))
          .sort(
            (a, b) =>
              new Date(b.$createAt).getTime() - new Date(a.$createAt).getTime()
          );
        setNews(sortedNewsItems);
      } catch (error) {
        console.error("Error fetching news or checking session:", error);
      } finally {
        setLoading(false);
      }
    };

    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    checkSessionAndFetchNews();
  }, [darkMode]);

  const handleLogout = async () => {
    try {
      await account.deleteSession("current");
      setIsLoggedIn(false);
      setNews([]);
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", newMode.toString());
      return newMode;
    });
  };

  const groupByDate = (newsItems: NewsItem[]) => {
    return newsItems.reduce((acc: { [key: string]: NewsItem[] }, item) => {
      const date = format(new Date(item.$createAt), "dd-MM-yyyy");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  const groupByTime = (newsItems: NewsItem[]) => {
    return newsItems.reduce((acc: { [key: string]: NewsItem[] }, item) => {
      const time = format(new Date(item.$createAt), "HH:mm");
      if (!acc[time]) {
        acc[time] = [];
      }
      acc[time].push(item);
      return acc;
    }, {});
  };

  const groupedNews = groupByDate(news);

  const sortedGroupedNews = Object.keys(groupedNews).reduce((acc, date) => {
    acc[date] = groupByTime(groupedNews[date]);
    Object.keys(acc[date]).forEach((time) => {
      acc[date][time] = acc[date][time].sort(
        (a, b) => parseInt(b.related) - parseInt(a.related)
      );
    });
    return acc;
  }, {} as { [key: string]: { [key: string]: NewsItem[] } });

  const toggleCollapse = (date: string) => {
    setCollapsed((prev) => ({ ...prev, [date]: !prev[date] }));
  };

  const toggleTimeCollapse = (time: string) => {
    setTimeCollapsed((prev) => ({ ...prev, [time]: !prev[time] }));
  };

  if (loading) {
    return (
      <div
        className={`flex flex-col items-center justify-center min-h-screen bg-gray-100 ${
          darkMode ? "dark:bg-neutral-900" : "bg-white"
        } text-gray-900 dark:text-neutral-300`}
      >
        <img src={botImg} alt="Logo" className="w-24 h-24 animate-bounce" />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "dark bg-neutral-900" : "bg-gray-100"
      } px-4`}
      dir={isLoggedIn ? "rtl" : "ltr"}
    >
      <div className="max-w-2xl mx-auto">
        <Header
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          isLoggedIn={isLoggedIn}
          handleLogout={handleLogout}
        />
        {isLoggedIn ? (
          <NewsList
            sortedGroupedNews={sortedGroupedNews}
            collapsed={collapsed}
            timeCollapsed={timeCollapsed}
            toggleCollapse={toggleCollapse}
            toggleTimeCollapse={toggleTimeCollapse}
            darkMode={darkMode}
          />
        ) : (
          <LoginForm
            account={account}
            setIsLoggedIn={setIsLoggedIn}
            setNews={setNews}
            darkMode={darkMode}
          />
        )}
      </div>
    </div>
  );
};

export default App;
